import { AxiosClient } from '@/app/shared/services';
import { toFormData } from '@/app/shared/services/helpers';

const state = {
  offers: {
    data: [],
  },
  currentOffer: {
    keitaro_name: '',
  },
  isLoading: false,
  isSuccess: false,
  session: '',
};

const getters = {
  CURRENT_OFFER: (state) => state.currentOffer,
  OFFERS: (state) => state.offers.data,
  IS_OFFER_LOADING: (state) => state.isLoading,
  IS_OFFER_SUCCESS: (state) => state.isSuccess,
};

const mutations = {
  SET_OFFERS: (state, offers) => {
    state.offers.data = offers;
  },
  SET_CURRENT_OFFER: (state, offer) => {
    state.currentOffer = offer;
  },
  CHANGE_SHOW: (state, offer) => {
    offer.show = !offer.show;
  },
  SET_OFFERS_LOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  SET_OFFERS_LOADING_SUCCESS: (state, isSuccess) => {
    state.isSuccess = isSuccess;
  },
};

const actions = {
  GET_OFFERS: (context, params) => {
    context.commit('SET_OFFERS_LOADING', true);
    return new Promise((resolve, reject) => {
      AxiosClient.get('/offer/get', { params })
        .then((data) => {
          context.commit('SET_OFFERS', data.data);
          context.commit('SET_OFFERS_LOADING_SUCCESS', true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },

  UPDATE_DEFAULT_OFFERS: (context) => {
    context.commit('SET_OFFERS_LOADING', true);
    return new Promise((resolve, reject) => {
      AxiosClient.post('/offer/update_default')
        .then((data) => {
          state.offers.data = data.data;
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },

  GET_OFFER_BY_ID: (context, offer_id) => {
    context.commit('SET_OFFERS_LOADING', true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/offer/get/${offer_id}`)
        .then((response) => {
          context.commit('SET_CURRENT_OFFER', response.data);
          resolve(response.data);
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },

  UPDATE_OFFER: (context, offer) => {
    context.commit('SET_OFFERS_LOADING', true);
    return new Promise((resolve, reject) => {
      const form = toFormData();
      form.append('img_with_erid', context.state.currentOffer.img_with_erid);
      form.append('img_without_erid', context.state.currentOffer.img_without_erid);
      form.append('json', JSON.stringify(context.state.currentOffer));
      AxiosClient.post(`/offer/update/${offer.id}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },

  CREATE_OFFER: (context, offer) => {
    context.commit('SET_OFFERS_LOADING', true);
    return new Promise((resolve, reject) => {
      const form = toFormData();
      form.append('img_with_erid', context.state.currentOffer.img_with_erid);
      form.append('img_without_erid', context.state.currentOffer.img_without_erid);
      form.append('json', JSON.stringify(offer));
      AxiosClient.post("/offer/create", form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },

  UPDATE_OFFER_SHOW: (context, offer) => {
    context.commit('CHANGE_SHOW', offer);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/offer/update_show/${offer.id}`, { "show": offer.show })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit('SET_OFFERS_LOADING', false);
        });
    });
  },
  DELETE_OFFER: (context, offerId) => new Promise((resolve, reject) => {
    AxiosClient.post(`/offer/delete/${offerId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
