import VuePageTransition from 'vue-page-transition';
import Vue from 'vue';
import Buefy from 'buefy';
import App from './app/app.vue';
import store from './app/app-state';
import vuetify from './plugins/vuetify';
import router from './app/app-routes';
import Toaster from './plugins/toaster';
import 'buefy/dist/buefy.css';
import './buefy-variables.scss';

Vue.use(Buefy);
Vue.use(VuePageTransition);
Vue.use(Toaster);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
