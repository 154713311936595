import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/resales",
    name: "Список партнеров",
    component: () => import("./views/resaleList"),
    beforeEnter: Guard([Middlewares.authRequired, Middlewares.adminAccess]),
  },
  {
    path: "/partner-api-integration",
    name: "API интеграция партнёров",
    component: () => import("./views/partnerApiIntegration"),
    beforeEnter: Guard([Middlewares.authRequired, Middlewares.adminAccess]),
  }
];
