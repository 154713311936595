import store from "@/app/app-state";

const Middlewares = {
  authByToken(to, from, next) {
    if (!store.getters.IS_LOGGED_IN && to.query.token) {
      store
        .dispatch("AUTH_BY_TOKEN", to.query.token)
        .then(() => {
          next(undefined);
        })
        .catch(() => {
          next("/login");
        });
    } else {
      next(undefined);
    }
  },
  authByApiKey(to, from, next) {
    if (!store.getters.IS_LOGGED_IN && to.query.api_key) {
      store
        .dispatch("AUTH_BY_API_KEY", to.query.api_key)
        .then(() => {
          next(undefined);
        })
        .catch(() => {
          next("/login");
        });
    } else {
      next(undefined);
    }
  },
  authRequired(to, from, next) {
    const route = store.getters.IS_LOGGED_IN ? undefined : "/login";
    next(route);
  },
  unAuthRequired(to, from, next) {
    const route = !store.getters.IS_LOGGED_IN ? undefined : "/panel";
    next(route);
  },
  adminAccess(to, from, next) {
      const route = store.getters.IS_ADMIN ? undefined : "/404";
      next(route);
  },
};

export { Middlewares };
