import { AxiosClient } from '@/app/shared/services';
import { toFormData } from '../../shared/services/helpers';
import Lodash from 'lodash';

const state = {
  modals: {
    confirm_deletion: false,
    profile_details: false,
    password_change: false,
    profile_details_loading: false,
  },
  filters: {
    role: '',
    status: '',
  },
  profiles: {
    data: [],
    isLoading: false,
    isSuccess: false,
    haveSuperuser: false,
  },
  teamleads: [],
  currentProfile: {
    isLoading: false,
  },
  link: {
    url: "",
    expires_at: 15,
    role: "",
    isLoading: false,
    isSuccess: false,
  },
  passwordRules: [
    v => !!v || 'Введите пароль'
    // v => (v && v.length >= 8) || 'Пароль должен содержать минимум 8 символов',
    // v => /^[A-Za-z0-9@$!%*?&-.]+$/.test(v) || 'Пароль должен содержать буквы латинского алфавита, цифры или специальные символы (@$!%*?&-.)',
    // v => /[A-Z]/.test(v) || 'Пароль должен содержать минимум одну заглавную букву',
    // v => /[0-9]/.test(v) || 'Пароль должен содержать минимум одну цифру'
  ],
};

const getters = {
  GET_PROFILES: (state) => state.profiles.data,
  HAVE_SUPERUSER: (state) => state.profiles.haveSuperuser,
  GET_TEAMLEAD_DATA: (state) => state.teamleads,
  GET_LINK: (state) => state.link,
  GET_CURRENT_PROFILE: (state) => state.currentProfile,
  IS_CONFIRM_DELETION_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  IS_PROFILE_DETAILS_MODAL_OPENED: (state) => state.modals.profile_details,
  IS_PASSWORD_CHANGE_MODAL_OPENED: (state) => state.modals.password_change,
  PROFILES_IS_LOADING: (state) => state.profiles.isLoading,
  PROFILES_LOADING_SUCCESS: (state) => state.profiles.isSuccess,
  PROFILE_IS_LOADING: (state) => state.currentProfile.isLoading,
  LINK_IS_LOADING: (state) => state.link.isLoading,
  PASSWORD_RULES: (state) => state.passwordRules,
  IS_PROFILE_DETAILS_LOADING: (state) => state.modals.profile_details_loading,
};

const mutations = {
  SET_CONFIRM_DELETION_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_PROFILE_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.profile_details = isOpen),
  SET_PASSWORD_CHANGE_MODAL_IS_OPENED: (state, isOpen) => (state.modals.password_change = isOpen),
  SET_PROFILES: (state, data) => {
    state.profiles.data = data.data;
    state.profiles.haveSuperuser = state.profiles.data.some(profile => profile.role === 'superuser');
  },
  SET_HAVE_SUPERUSER: (state, data) => {
    state.profiles.haveSuperuser = data;
  },
  SET_CURRENT_PROFILE: (state, data) => {
    // Делаем копию, чтобы не возникало проблем с прямой передачей
    state.currentProfile = Lodash.cloneDeep(data);
  },
  SET_DEFAULT_PROFILE: (state) => {
    state.currentProfile = {
      isLoading: false,
    };
  },
  SET_LINK: (state, data) => {
    state.link.url = data;
  },
  SET_TEAMLEADS: (state, teamleads) => {
    state.teamleads = teamleads.data;
  },
  SET_PROFILES_LOADING: (state, isLoading) => {
    state.profiles.isLoading = isLoading;
  },
  SET_PROFILES_LOADING_SUCCESS: (state, isSuccess) => {
    state.profiles.isSuccess = isSuccess;
  },
  SET_PROFILE_IS_LOADING: (state, isLoading) => {
    state.currentProfile.isLoading = isLoading;
  },
  SET_LINK_IS_LOADING: (state, isLoading) => {
    state.link.isLoading = isLoading;
  },
  SET_LOADING_PROFILE_DETAILS: (state, isLoading) => {
    state.modals.profile_details_loading = isLoading;
  },
};

const actions = {
  GET_PROFILES: (context, params) => new Promise((resolve, reject) => {
    AxiosClient.get('/profile/get', { params })
      .then((data) => {
        context.commit(params.type, data);
        resolve(data);
        context.commit("SET_PROFILES_LOADING_SUCCESS", true);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        context.commit("SET_PROFILES_LOADING", false);
      });
  }),
  GET_SUPERUSER: (context) => new Promise((resolve, reject) => {
    AxiosClient.get('/profile/check_superuser')
      .then((data) => {
        if (data.data.superuser !== undefined) {
          context.commit("SET_HAVE_SUPERUSER", data.data.superuser);
          resolve(data);
        }
        reject(data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        context.commit("SET_PROFILES_LOADING", false);
      });
  }),
  CONFIRM_DELETION_MODAL: (context, isOpen) => {
    context.commit('SET_CONFIRM_DELETION_MODAL_IS_OPENED', isOpen);
  },
  PROFILE_DETAILS_MODAL: (context, isOpen) => {
    context.commit('SET_PROFILE_DETAILS_MODAL_IS_OPENED', isOpen);
  },
  CURRENT_PROFILE: (context, profile) => {
    context.commit('SET_CURRENT_PROFILE', profile);
  },
  CHANGE_PASSWORD_MODAL: (context, isOpen) => {
    context.commit("SET_PASSWORD_CHANGE_MODAL_IS_OPENED", isOpen);
  },
  DELETE_SELECTED_PROFILE: (context, profileId) => new Promise((resolve, reject) => {
    context.commit("SET_PROFILES_LOADING", true);
    AxiosClient.post(`/profile/delete/${profileId}`)
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        context.commit("SET_PROFILES_LOADING", false);
      });
  }),
  CREATE_PROFILE: (context) => {
    context.commit("SET_LOADING_PROFILE_DETAILS", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(context.state.currentProfile);
      AxiosClient.post("/profile/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_LOADING_PROFILE_DETAILS", false);
        });
    });
  },
  UPDATE_PROFILE: (context, profile) => {
    context.commit("SET_LOADING_PROFILE_DETAILS", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(profile);
      AxiosClient.post(`/profile/update/${profile.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            reject(403);
          } else {
            reject(error);
          }
        })
        .finally(() => {
          context.commit("SET_LOADING_PROFILE_DETAILS", false);
        });
    });
  },
  CHANGE_USER_PASSWORD: (context, password) => {
    context.commit("SET_PROFILE_IS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profile/change_password", { password })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_PROFILE_IS_LOADING", false);
        });
    });
  },
  GET_LINK: (context) => {
    context.commit("SET_LINK_IS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profile/reg_link", context.state.link)
        .then((data) => {
          context.commit("SET_LINK", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_LINK_IS_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
