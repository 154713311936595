import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  domains: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    domains_details: false,
    confirm_deletion: false,
  },
  currentDomain: {
    isLoading: false,
    data: {},
  },
};

const getters = {
  DOMAINS: (state) => state.domains.data,
  IS_DOMAINS_LOADING: (state) => state.domains.isLoading,
  IS_DOMAINS_SUCCESS: (state) => state.domains.isSuccess,
  IS_DOMAIN_DETAILS_MODAL_OPENED: (state) => state.modals.domains_details,
  IS_CONFIRM_DELETION_DOMAIN_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  GET_CURRENT_DOMAIN: (state) => state.currentDomain.data,
  IS_CURRENT_DOMAIN_LOADING: (state) => state.currentDomain.isLoading,
};

const mutations = {
  SET_DOMAINS: (state, domains) => {
    state.domains.data = domains;
  },
  SET_DOMAINS_LOADING: (state, isLoading) => {
    state.domains.isLoading = isLoading;
  },
  SET_DOMAINS_LOADING_SUCCESS: (state, isSuccess) => {
    state.domains.isSuccess = isSuccess;
  },
  SET_DOMAIN_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.domains_details = isOpen),
  SET_CONFIRM_DELETION_DOMAIN_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_CURRENT_DOMAIN: (state, domain) => {
    state.currentDomain.data = domain;
  },
  SET_CURRENT_DOMAIN_LOADING: (state, isLoading) => {
    state.currentDomain.isLoading = isLoading;
  },
};

const actions = {
  GET_DOMAINS: (context) => {
    context.commit("SET_DOMAINS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/domain/get")
        .then((data) => {
          context.commit("SET_DOMAINS", data.data);
          context.commit("SET_DOMAINS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_DOMAINS_LOADING", false);
        });
    });
  },
  DOMAIN_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_DOMAIN_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_DOMAIN_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_DOMAIN_MODAL_IS_OPENED", isOpen);
  },
  CREATE_DOMAIN: (context, domain) => {
    context.commit("SET_DOMAINS_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(domain);
      AxiosClient.post("/domain/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_DOMAINS_LOADING", false);
        });
    });
  },
  DELETE_DOMAIN: (context, domainId) => new Promise((resolve, reject) => {
    AxiosClient.post(`/domain/delete/${domainId}`)
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        context.commit("SET_DOMAINS_LOADING", false);
      });
  }),
  CURRENT_DOMAIN: (context, domain) => {
    context.commit('SET_CURRENT_DOMAIN', domain);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
