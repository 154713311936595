import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "json",
    name: "Настройки JSON",
    component: () => import("./views/jsonSettings.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  }
];
