<template>
    <div>
        <section class="hero is-link is-large">
            <div class="hero-body">
                <div class="has-text-centered">
                    <h1 class="title is-2">
                        404
                    </h1>
                    <h2 class="subtitle is-4">
                        Страница не найдена :(<br>
                    </h2>
                    <b-button @click="mainPage()" type="is-primary"
                              icon-right="chevron-right"
                              size="is-medium">Перейти на главную</b-button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    mainPage() {
      this.$router.push({ path: '/' }).catch(()=>{});
    },
  },
};

</script>
