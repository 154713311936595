import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  servers: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    server_details: false,
    confirm_deletion: false,
  },
  currentServer: {
    isLoading: false,
    data: {},
  },
};

const getters = {
  GET_SERVERS: (state) => state.servers.data,
  IS_SERVERS_LOADING: (state) => state.servers.isLoading,
  IS_SERVERS_SUCCESS: (state) => state.servers.isSuccess,
  IS_SERVER_DETAILS_MODAL_OPENED: (state) => state.modals.server_details,
  IS_CONFIRM_DELETION_SERVER_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  GET_CURRENT_SERVER: (state) => state.currentServer.data,
  IS_CURRENT_SERVER_LOADING: (state) => state.currentServer.isLoading,
};

const mutations = {
  SET_SERVERS: (state, servers) => {
    state.servers.data = servers;
  },
  SET_SERVERS_LOADING: (state, isLoading) => {
    state.servers.isLoading = isLoading;
  },
  SET_SERVERS_LOADING_SUCCESS: (state, isSuccess) => {
    state.servers.isSuccess = isSuccess;
  },
  SET_SERVER_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.server_details = isOpen),
  SET_CONFIRM_DELETION_SERVER_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_CURRENT_SERVER: (state, server) => {
    state.currentServer.data = server;
  },
  SET_CURRENT_SERVER_LOADING: (state, isLoading) => {
    state.currentServer.isLoading = isLoading;
  },
};

const actions = {
  GET_SERVERS: (context) => {
    context.commit("SET_SERVERS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/server/get")
        .then((data) => {
          context.commit("SET_SERVERS", data.data);
          context.commit("SET_SERVERS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_SERVERS_LOADING", false);
        });
    });
  },
  SERVER_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_SERVER_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_SERVER_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_SERVER_MODAL_IS_OPENED", isOpen);
  },
  UPDATE_CURRENT_SERVER: (context, server) => {
    context.commit("SET_CURRENT_SERVER_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(server);
      AxiosClient.post(`/server/update/${server.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_SERVER_LOADING", false);
        });
    });
  },
  CREATE_SERVER: (context, server) => {
    context.commit("SET_CURRENT_SERVER_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(server);
      AxiosClient.post("/server/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_SERVER_LOADING", false);
        });
    });
  },
  CURRENT_SERVER: (context, server) => {
    context.commit('SET_CURRENT_SERVER', server);
  },
  DELETE_SERVER: (context, serverId) => {
    context.commit("SET_CURRENT_SERVER_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/server/delete/${serverId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_SERVER_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
