import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  firebases: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    details: false,
    details_loading: false,

    confirm_deletion_firebase: {
      isOpen: false,
      isLoading: false,
    }
  },
  currentResale: {
    data: {},
  },
};

const getters = {
  GET_FIREBASES: (state) => state.firebases.data,
  IS_FIREBASES_LOADING: (state) => state.firebases.isLoading,

  GET_FIREBASE_DETAILS_MODAL_OPENED: (state) => state.modals.details,
  GET_FIREBASE_DETAILS_MODAL_LOADING: (state) => state.modals.details_loading,

  GET_CURRENT_FIREBASE: (state) => state.currentResale.data,

  // Модалка удаления
  GET_FIREBASES_DETAILS_MODAL_CONFIRM_DELETION: (state) => state.modals.confirm_deletion_firebase.isOpen,
  GET_FIREBASES_DETAILS_MODAL_CONFIRM_DELETION_LOADING: (state) => state.modals.confirm_deletion_firebase.isLoading,
};

const mutations = {
  SET_FIREBASES: (state, firebases) => {
    state.firebases.data = firebases;
  },
  SET_FIREBASES_LOADING: (state, isLoading) => {
    state.firebases.isLoading = isLoading;
  },

  SET_FIREBASE_DETAILS_MODAL_OPENED: (state, isOpen) => {
    state.modals.details = isOpen;
  },
  SET_FIREBASE_DETAILS_MODAL_LOADING: (state, isLoading) => {
    state.modals.details_loading = isLoading;
  },

  SET_CURRENT_FIREBASE: (state, currentFirebase) => {
    // Делаем копию, чтобы не возникало проблем с прямой передачей
    state.currentResale.data =  Lodash.cloneDeep(currentFirebase);
  },

  // Модалка удаления
  SET_CONFIRM_DELETION_FIREBASE_MODAL: (state, isOpen) => {
    state.modals.confirm_deletion_firebase.isOpen = isOpen;
  },
  SET_CONFIRM_DELETION_FIREBASE_MODAL_LOADING: (state, isLoading) => {
    state.modals.confirm_deletion_firebase.isLoading = isLoading;
  },
};

const actions = {
  GET_FIREBASES: (context) => {
    context.commit("SET_FIREBASES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/firebases/")
        .then((data) => {
          context.commit("SET_FIREBASES", data.data);
          resolve();
        })
        .catch((error) => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FIREBASES_LOADING", false);
        });
    });
  },
  GET_FIREBASE_BY_ID: (context, id) => {
    context.commit("SET_FIREBASES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/firebases/${id}`)
        .then((data) => {
          context.commit("SET_CURRENT_FIREBASE", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FIREBASES_LOADING", false);
        });
    });
  },

  CREATE_FIREBASE: (context, params) => {
    context.commit("SET_FIREBASE_DETAILS_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/firebases/", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          let errorMessage;
          switch (error.response.data.error) {
            case 'The file could not be saved':
              errorMessage = "Не удалось сохранить файл";
              break;
            case 'File not found':
              errorMessage = "Файл не найден";
              break;
            default:
              errorMessage = "Произошла ошибка при создании";
          }

          reject(errorMessage);
        })
        .finally(() => {
          context.commit("SET_FIREBASE_DETAILS_MODAL_LOADING", false);
        });
    });
  },


  DELETE_FIREBASE: (context, id) => {
    context.commit("SET_CONFIRM_DELETION_FIREBASE_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.delete(`/firebases/${id}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CONFIRM_DELETION_FIREBASE_MODAL_LOADING", false);
        });
    });
  },

  UPDATE_FIREBASE: (context, { id, form }) => {
    context.commit("SET_FIREBASE_DETAILS_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.put(`/firebases/${id}`, form)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FIREBASE_DETAILS_MODAL_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
