import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  vkTokens: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    vk_details: false,
    confirm_deletion: false,
  },
  currentVkToken: {
    isLoading: false,
    data: {
    },
  },
};

const getters = {
  VK_TOKENS: (state) => state.vkTokens.data,
  IS_VK_TOKENS_LOADING: (state) => state.vkTokens.isLoading,
  IS_VK_TOKENS_SUCCESS: (state) => state.vkTokens.isSuccess,
  IS_VK_DETAILS_MODAL_OPENED: (state) => state.modals.vk_details,
  IS_CONFIRM_DELETION_VK_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  GET_CURRENT_VK_TOKEN: (state) => state.currentVkToken.data,
  IS_CURRENT_VK_TOKEN_LOADING: (state) => state.currentVkToken.isLoading,
};

const mutations = {
  SET_VK_TOKENS: (state, vkTokens) => {
    state.vkTokens.data = vkTokens;
  },
  SET_VK_TOKENS_LOADING: (state, isLoading) => {
    state.vkTokens.isLoading = isLoading;
  },
  SET_VK_TOKENS_LOADING_SUCCESS: (state, isSuccess) => {
    state.vkTokens.isSuccess = isSuccess;
  },
  SET_VK_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.vk_details = isOpen),
  SET_CONFIRM_DELETION_VK_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_CURRENT_VK_TOKEN: (state, vkToken) => {
    state.currentVkToken.data = vkToken;
  },
  SET_CURRENT_VK_TOKEN_LOADING: (state, isLoading) => {
    state.currentVkToken.isLoading = isLoading;
  },
};

const actions = {
  GET_VK_TOKENS: (context) => {
    context.commit("SET_VK_TOKENS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/vk/get")
        .then((data) => {
          context.commit("SET_VK_TOKENS", data.data);
          context.commit("SET_VK_TOKENS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_VK_TOKENS_LOADING", false);
        });
    });
  },
  VK_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_VK_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_VK_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_VK_MODAL_IS_OPENED", isOpen);
  },
  CREATE_VK_TOKEN: (context, vkToken) => {
    context.commit("SET_CURRENT_VK_TOKEN_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/vk/create", vkToken)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_VK_TOKEN_LOADING", false);
        });
    });
  },
  CURRENT_VK_TOKEN: (context, vkToken) => {
    context.commit('SET_CURRENT_VK_TOKEN', vkToken);
  },
  DELETE_VK_TOKEN: (context, vkTokenId) => {
    context.commit("SET_CURRENT_VK_TOKEN_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/vk/delete/${vkTokenId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_VK_TOKEN_LOADING", false);
        });
    });
  },
  CHANGE_USAGE: (context, tokenId) => {
    context.commit("SET_CURRENT_VK_TOKEN_LOADING", true);
    return new Promise((resolve, reject) => {
        AxiosClient.post(`/vk/change_usage/${tokenId}`)
            .then((response) => resolve(response.data))
            .catch(() => reject())
            .finally(() => {
                context.commit("SET_CURRENT_VK_TOKEN_LOADING", false);
            });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
