import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  data: {
    data: [],
    isLoading: false,
  },
};

const getters = {
  GET_DATA: (state) => state.data.data,
  IS_DATA_LOADING: (state) => state.data.isLoading,
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data.data = data;
  },
  SET_DATA_LOADING: (state, data) => {
    state.data.isLoading = data;
  },
};

const actions = {
  GET_COLLECTION_BY_ID: (context, { idFirebase, idCollection }) => {
    context.commit("SET_DATA_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/firebases/${idFirebase}/collections/${idCollection}`)
        .then((data) => {
          context.commit("SET_DATA", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_DATA_LOADING", false);
        });
    });
  },
  SAVE_COLLECTION: (context, { idFirebase, idCollection, data }) => {
    context.commit("SET_DATA_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.put(`/firebases/${idFirebase}/collections/${idCollection}/data`, data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_DATA_LOADING", false);
        });
    });
  },
  SAVE_TO_FIREBASE_COLLECTION: (context, { idFirebase, idCollection, data }) => {
    context.commit("SET_DATA_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/firebases/${idFirebase}/collections/${idCollection}/data`, data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_DATA_LOADING", false);
        });
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
