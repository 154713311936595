import { AxiosClient } from '@/app/shared/services';
import { toFormData } from '../../shared/services/helpers';

import Vue from 'vue';
// встроенная библиотека для реактивной сортировки
import orderBy from "lodash/orderBy";

const state = {
  applications: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  currentApplication: {
    package_name: "",
    offers: [],
  },
  modals: {
    banner_details: false,
  },
  currentBanner: {
    data: {
      name: "",
      description: "",
      typeOfOffer: "banner"
    },
    isLoading: false,
    isSuccess: false,
  },
};

// получение сегодняшней даты в нужном формате
function today(){
  let today = new Date();

  // Получаем значения года, месяца и дня
  let year = today.getFullYear();
  // Месяцы в JavaScript начинаются с 0, поэтому прибавляем 1
  let month = String(today.getMonth() + 1).padStart(2, '0');
  let day = String(today.getDate()).padStart(2, '0');

  // Формируем строку в нужном формате
  let formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

const getters = {
  CURRENT_APPLICATION: (state) => state.currentApplication,
  APPLICATIONS: (state) => state.applications.data,
  IS_APPLICATIONS_LOADING: (state) => state.applications.isLoading,
  IS_APPLICATIONS_SUCCESS: (state) => state.applications.isSuccess,
  IS_BANNER_DETAILS_MODAL_OPENED: (state) => state.modals.banner_details,
  GET_CURRENT_BANNER: (state) => state.currentBanner.data,
  IS_CURRENT_BANNER_LOADING: (state) => state.currentBanner.isLoading,
};

const mutations = {
  SET_APPLICATIONS: (state, applications) => {
    state.applications.data = applications;
  },
  SET_CURRENT_APPLICATION: (state, application) => {
    state.currentApplication = application;
  },
  SET_APPLICATIONS_LOADING: (state, isLoading) => {
    state.applications.isLoading = isLoading;
  },
  SET_APPLICATIONS_LOADING_SUCCESS: (state, isSuccess) => {
    state.applications.isSuccess = isSuccess;
  },
  SET_BANNER_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.banner_details = isOpen),
  SET_CURRENT_BANNER: (state, banner) => {
    state.currentBanner.data = banner;
  },
  SET_CURRENT_BANNER_LOADING: (state, isLoading) => {
    state.currentBanner.isLoading = isLoading;
  },
};

const actions = {
  GET_APPLICATIONS: (context, params) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/application/get", { params })
        .then((data) => {
          context.commit("SET_APPLICATIONS", data.data);
          context.commit("SET_APPLICATIONS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },
  GET_APPLICATION_BY_ID: (context, application_id) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/application/get/${application_id}`)
        .then((data) => {
          context.commit("SET_CURRENT_APPLICATION", data.data);
          state.currentApplication.epcType = "buying";
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },
  CREATE_APPLICATION: (context) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(context.state.currentApplication);
      AxiosClient.post("/application/create",  form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },
  UPDATE_APPLICATION: (context, applicationId) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      context.state.currentApplication.offers = context.state.currentApplication.offers.map((item, index) => {
        item.order = index;
        return item;
      });
      const form = toFormData(context.state.currentApplication);
      AxiosClient.post(`/application/update/${applicationId}`,  form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },
  DELETE_APPLICATION: (context, applicationId) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/application/delete/${applicationId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },
  UPDATE_OFFERS: (context, application) => {
    context.commit("SET_APPLICATIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/application/update_app/${application.id}`, { "updateAll": application.updateAll })
        .then((data) => {
          state.currentApplication.isNativeSort = false;
          state.currentApplication.offers = data.data.offers;
          state.currentApplication.offers_native = data.data.offers_native;
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },

  UPDATE_OFFERS_BY_TYPE: (context, params) => {
    context.commit("SET_APPLICATIONS_LOADING", true);

    if (state.currentApplication.selectedDateEnd == null || state.currentApplication.selectedDateEnd == ''){
      state.currentApplication.selectedDateEnd = today();
    }

    let data = {
      "date_from": state.currentApplication.selectedDateStart,
      "date_to": state.currentApplication.selectedDateEnd,
      "params": params,
    };

    return new Promise((resolve, reject) => {
      AxiosClient.post(`/application/update_app_by_type/${params['applicationId']}`, data)
        .then((data) => {
          state.currentApplication.isNativeSort = false;
          state.currentApplication.offers = data.data.offers;
          state.currentApplication.offers_native = data.data.offers_native;
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_APPLICATIONS_LOADING", false);
        });
    });
  },

  DOWNLOAD_LANDING: (context, applicationId) => new Promise((resolve, reject) => {
    AxiosClient.get(`/application/download_landing/${applicationId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }),
  CURRENT_BANNER: (context, banner) => {
    context.commit('SET_CURRENT_BANNER', banner);
  },
  BANNER_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_BANNER_DETAILS_MODAL_IS_OPENED", isOpen);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
