import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  collections: {
    data: [],
    isLoading: false,
  },
  collection_modals: {
    deletion: {
      show: false,
      loading: false,
      idCollection: null,
    },
    detail: {
      show: false,
      loading: false,
    },
    upload: {
      show: false,
      loading: false,
    }
  },
  currentCollection: {
    name: undefined,
    id: null,
    fields: [],
    use_standard_fields: true
  },
};

const getters = {
  GET_COLLECTIONS: (state) => state.collections.data,
  IS_COLLECTIONS_LOADING: (state) => state.collections.isLoading,

  // модалка с удалением коллекции
  IS_CONFIRM_DELETION_COLLECTION_MODAL: (state) => state.collection_modals.deletion.show,
  IS_COLLECTIONS_DELETION_LOADING: (state) => state.collection_modals.deletion.loading,
  ID_COLLECTION_TO_DELETE: (state) => state.collection_modals.deletion.idCollection,

  // модалка для создания/изменения
  IS_DETAIL_COLLECTION_MODAL: (state) => state.collection_modals.detail.show,
  IS_DETAIL_COLLECTION_MODAL_LOADING: (state) => state.collection_modals.detail.loading,
  CURRENT_COLLECTION: (state) => state.currentCollection,

  // модалка для обновления коллекций с Firebase
  IS_CONFIRM_UPLOAD_COLLECTIONS_MODAL: (state) => state.collection_modals.upload.show,
  IS_COLLECTIONS_UPLOAD_LOADING: (state) => state.collection_modals.upload.loading,
};

const mutations = {
  SET_COLLECTIONS: (state, collections) => {
    state.collections.data = collections;
  },
  SET_COLLECTIONS_LOADING: (state, isLoading) => {
    state.collections.isLoading = isLoading;
  },

  // модалка с удалением коллекции
  SET_CONFIRM_DELETION_COLLECTION_MODAL: (state, show) => {
    state.collection_modals.deletion.show = show;
  },
  SET_COLLECTIONS_DELETION_LOADING: (state, loading) => {
    state.collection_modals.deletion.loading = loading;
  },
  SET_COLLECTIONS_DELETION_ID_COLLECTION: (state, idCollection) => {
    state.collection_modals.deletion.idCollection = idCollection;
  },

  // модалка для создания/изменения
  SET_DETAIL_COLLECTION_MODAL: (state, show) => {
    state.collection_modals.detail.show = show;
  },
  SET_DETAIL_COLLECTION_MODAL_LOADING: (state, loading) => {
    state.collection_modals.detail.loading = loading;
  },
  SET_CURRENT_COLLECTION: (state, collection) => {
    state.currentCollection = collection;
    state.currentCollection =  Lodash.cloneDeep(collection);
  },
  SET_DEFAULT_CURRENT_COLLECTION: (state) => {
    state.currentCollection = {
      name: undefined,
      id: null,
      fields: [],
      use_standard_fields: true
    };
  },

  // модалка для обновления коллекций с Firebase
  SET_CONFIRM_UPLOAD_COLLECTIONS_MODAL: (state, show) => {
    state.collection_modals.upload.show = show;
  },
  SET_COLLECTIONS_UPLOAD_LOADING: (state, loading) => {
    state.collection_modals.upload.loading = loading;
  }
};

const actions = {
  GET_COLLECTIONS: (context, idFirebase) => {
    context.commit("SET_COLLECTIONS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/firebases/${idFirebase}/collections/`)
        .then((data) => {
          context.commit("SET_COLLECTIONS", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_COLLECTIONS_LOADING", false);
        });
    });
  },

  UPLOAD_COLLECTIONS: (context, idFirebase) => {
    context.commit("SET_COLLECTIONS_UPLOAD_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/firebases/${idFirebase}/collections/upload`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 504) {
            console.error("504. Проблема с прокси");
            reject(504);
          } else {
            console.error("Произошла ошибка:", error.message);
          }
          reject();
        })
        .finally(() => {
          context.commit("SET_COLLECTIONS_UPLOAD_LOADING", false);
        });
    });
  },

  DELETE_COLLECTION: (context, { idFirebase, idCollection }) => {
    context.commit("SET_COLLECTIONS_DELETION_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.delete(`/firebases/${idFirebase}/collections/${idCollection}/`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 504) {
            console.error("504. Проблема с прокси");
            reject(504);
          } else {
            console.error("Произошла ошибка:", error.message);
          }
          reject();
        }).finally(() => {
          context.commit("SET_COLLECTIONS_DELETION_LOADING", false);
        });
    });
  },


  CREATE_COLLECTION: (context,  { collection, firebase }) => {
    context.commit("SET_DETAIL_COLLECTION_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/firebases/${firebase}/collections/`, collection)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 504) {
            console.error("504. Проблема с прокси");
            reject(504);
          } else {
            console.error("Произошла ошибка:", error.message);
          }
          reject();
        })
        .finally(() => {
          context.commit("SET_DETAIL_COLLECTION_MODAL_LOADING", false);
        });
    });
  },
  UPDATE_COLLECTION: (context,  { collection, firebase }) => {
    context.commit("SET_DETAIL_COLLECTION_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.put(`/firebases/${firebase}/collections/${collection.id}/`, collection)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.status === 504) {
            console.error("504. Проблема с прокси");
            reject(504);
          } else {
            console.error("Произошла ошибка:", error.message);
          }
          reject();
        })
        .finally(() => {
          context.commit("SET_DETAIL_COLLECTION_MODAL_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
