import { AxiosClient } from '@/app/shared/services';

const state = {
  landings: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
};

const getters = {
  LANDINGS: (state) => state.landings.data,
  IS_LANDINGS_LOADING: (state) => state.landings.isLoading,
  IS_LANDINGS_SUCCESS: (state) => state.landings.isSuccess,
};

const mutations = {
  SET_LANDINGS: (state, landings) => {
    state.landings.data = landings;
  },
  SET_LANDINGS_LOADING: (state, isLoading) => {
    state.landings.isLoading = isLoading;
  },
  SET_LANDINGS_LOADING_SUCCESS: (state, isSuccess) => {
    state.landings.isSuccess = isSuccess;
  },
};

const actions = {
  GET_LANDINGS: (context) => {
    context.commit("SET_LANDINGS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/landing/get")
        .then((data) => {
          context.commit("SET_LANDINGS", data.data);
          context.commit("SET_LANDINGS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_LANDINGS_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
