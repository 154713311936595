import Axios from 'axios';

Axios.defaults.baseURL =
  process.env.NODE_ENV == "development" ? process.env.VUE_APP_BASE_URL : `${location.origin}/api`;

const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";

Axios.defaults.headers.common.Authorization = Axios.defaults.headers.common.Authorization
  ? Axios.defaults.headers.common.Authorization
  : authToken;

const AxiosClient = Axios.create();

export default AxiosClient;
