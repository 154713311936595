import Guard from "vue-router-multiguard";
import { Middlewares } from "@/app/shared/services/app-router/middlewares";

export default [
  {
    path: "/login",
    name: "Вход",
    component: () => import("./views/login"),
  },
  {
    path: "/register",
    name: "Регистрация",
    component: () => import("./views/registration"),
    beforeEnter: Guard([Middlewares.unAuthRequired]),
  },
  {
    path: "/forgot-password",
    name: "Восстановление пароля",
    component: () => import("./views/forgotPassword"),
  },
];
