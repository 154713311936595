import { AxiosClient } from '@/app/shared/services';
import { toFormData } from '../../shared/services/helpers';

const state = {
  tasks: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
};

const getters = {
  TASKS: (state) => state.tasks.data,
  IS_TASKS_LOADING: (state) => state.tasks.isLoading,
  IS_TASKS_SUCCESS: (state) => state.tasks.isSuccess,
};

const mutations = {
  SET_TASKS: (state, tasks) => {
    state.tasks.data = tasks;
  },
  SET_TASKS_LOADING: (state, isLoading) => {
    state.tasks.isLoading = isLoading;
  },
  SET_TASKS_LOADING_SUCCESS: (state, isSuccess) => {
    state.tasks.isSuccess = isSuccess;
  },
};

const actions = {
  GET_TASKS: (context, params) => {
    context.commit("SET_TASKS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/tasks/get", { params })
        .then((data) => {
          context.commit("SET_TASKS", data.data);
          context.commit("SET_TASKS_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_TASKS_LOADING", false);
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
