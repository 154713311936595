import collections from "./collections/collections-states";
import json from "./json/json-states";
import data from "./data/data-states";

export default {
  modules: {
    collections,
    json,
    data
  }
};
