import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  templates: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    template_details: false,
    confirm_deletion: false,
    deletion_default_template: false,
    dont_have_default_template: false
  },
  currentTemplate: {
    isLoading: false,
    data: {
      type: "app"
    },
  },
  defaultTemplate: null,
};

const getters = {
  TEMPLATES: (state) => state.templates.data,
  IS_TEMPLATES_LOADING: (state) => state.templates.isLoading,
  IS_TEMPLATES_SUCCESS: (state) => state.templates.isSuccess,
  IS_TEMPLATE_DETAILS_MODAL_OPENED: (state) => state.modals.template_details,
  IS_CONFIRM_DELETION_TEMPLATE_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  GET_CURRENT_TEMPLATE: (state) => state.currentTemplate.data,
  IS_CURRENT_TEMPLATE_LOADING: (state) => state.currentTemplate.isLoading,
  IS_DELETION_DEFAULT_TEMPLATE_MODAL_OPENED: (state) => state.modals.deletion_default_template,
  DEFAULT_TEMPLATE: (state) => state.defaultTemplate,
  IS_DONT_HAVE_DEFAULT_TEMPLATE_MODAL_OPENED: (state) => state.modals.dont_have_default_template
};

const mutations = {
  SET_TEMPLATES: (state, templates) => {
    state.templates.data = templates;
  },
  SET_TEMPLATES_LOADING: (state, isLoading) => {
    state.templates.isLoading = isLoading;
  },
  SET_TEMPLATES_LOADING_SUCCESS: (state, isSuccess) => {
    state.templates.isSuccess = isSuccess;
  },
  SET_TEMPLATE_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.template_details = isOpen),
  SET_CONFIRM_DELETION_TEMPLATE_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_CURRENT_TEMPLATE: (state, template) => {
    // Делаем копию, чтобы не возникало проблем с прямой передачей
    state.currentTemplate.data = Lodash.cloneDeep(template);
  },
  SET_CURRENT_TEMPLATE_LOADING: (state, isLoading) => {
    state.currentTemplate.isLoading = isLoading;
  },

  SET_DELETION_DEFAULT_TEMPLATE_MODAL: (state, isOpen) => {
    state.modals.deletion_default_template = isOpen;
  },
  SET_DONT_HAVE_DEFAULT_TEMPLATE_MODAL: (state, isOpen) => {
    state.modals.dont_have_default_template = isOpen;
  },
  SET_DEFAULT_TEMPLATE: (state, template) => {
    state.defaultTemplate = template.id;
  }
};

const actions = {
  GET_TEMPLATES: (context, params) => {
    context.commit("SET_TEMPLATES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/template/get", { params })
        .then((data) => {
          context.commit("SET_TEMPLATES", data.data);
          context.commit("SET_TEMPLATES_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_TEMPLATES_LOADING", false);
        });
    });
  },
  GET_DEFAULT_TEMPLATE: (context) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/template/default-template")
        .then((data) => {
          context.commit("SET_DEFAULT_TEMPLATE", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  SET_NEW_DEFAULT_VALUE: (context, template_data) => {
    return new Promise((resolve, reject) => {
      AxiosClient.put("/template/default-template/" + template_data.id, {
        is_default: template_data.is_default
      })
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  TEMPLATE_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_TEMPLATE_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_TEMPLATE_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_TEMPLATE_MODAL_IS_OPENED", isOpen);
  },
  UPDATE_CURRENT_TEMPLATE: (context, template) => {
    context.commit("SET_CURRENT_TEMPLATE_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(template);
      AxiosClient.post(`/template/update/${template.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_TEMPLATE_LOADING", false);
        });
    });
  },
  CREATE_TEMPLATE: (context, template) => {
    context.commit("SET_CURRENT_TEMPLATE_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(template);
      AxiosClient.post("/template/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_TEMPLATE_LOADING", false);
        });
    });
  },
  CURRENT_TEMPLATE: (context, template) => {
    context.commit('SET_CURRENT_TEMPLATE', template);
  },
  DELETE_TEMPLATE: (context, templateId) => {
    context.commit("SET_CURRENT_TEMPLATE_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/template/delete/${templateId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_TEMPLATE_LOADING", false);
        });
    });
  },
  UPDATE_TEMPLATES: (context) => {
    context.commit("SET_TEMPLATES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/template/update_text")
        .then(() => {
          context.commit("SET_TEMPLATES_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_TEMPLATES_LOADING", false);
        });
    });
  },
  UPDATE_TEMPLATES_BY_ID: (context, template) => {
    context.commit("SET_TEMPLATES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/template/update_text/${template.id}`)
        .then(() => {
          context.commit("SET_TEMPLATES_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_TEMPLATES_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
