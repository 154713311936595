import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  resales: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  resalesIntegration: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  modals: {
    RESALE_details: false,
    confirm_deletion: false,

    RESALE_INTEGRATION_details: false,
    confirm_integration_deletion: false,
  },
  currentResale: {
    isLoading: false,
    data: {},
  },

  currentResaleIntegration: {
    isLoading: false,
    data: {},
  },

  postbacks: {
    data: [],
  },
};

const getters = {
  GET_RESALES: (state) => state.resales.data,
  IS_RESALES_LOADING: (state) => state.resales.isLoading,
  IS_RESALES_SUCCESS: (state) => state.resales.isSuccess,
  IS_RESALE_DETAILS_MODAL_OPENED: (state) => state.modals.RESALE_details,
  IS_CONFIRM_DELETION_RESALE_MODAL_OPENED: (state) => state.modals.confirm_deletion,
  GET_CURRENT_RESALE: (state) => state.currentResale.data,
  IS_CURRENT_RESALE_LOADING: (state) => state.currentResale.isLoading,

  GET_RESALES_INTEGRATION: (state) => state.resalesIntegration.data,
  IS_RESALES_INTEGRATION_LOADING: (state) => state.resalesIntegration.isLoading,
  IS_RESALES_DETAILS_MODAL_OPENED: (state) => state.modals.RESALE_INTEGRATION_details,
  IS_CONFIRM_DELETION_RESALE_INTEGRATION_MODAL_OPENED: (state) => state.modals.confirm_integration_deletion,
  GET_CURRENT_RESALE_INTEGRATION: (state) => state.currentResaleIntegration.data,
  IS_CURRENT_RESALE_INTEGRATION_LOADING: (state) => state.currentResaleIntegration.isLoading,

  GET_POSTBACKS: (state) => state.postbacks.data,
};

const mutations = {
  SET_RESALES: (state, resales) => {
    state.resales.data = resales;
  },
  SET_RESALES_LOADING: (state, isLoading) => {
    state.resales.isLoading = isLoading;
  },
  SET_RESALES_LOADING_SUCCESS: (state, isSuccess) => {
    state.resales.isSuccess = isSuccess;
  },
  SET_RESALE_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.RESALE_details = isOpen),
  SET_CONFIRM_DELETION_RESALE_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_deletion = isOpen),
  SET_CURRENT_RESALE: (state, resale) => {
    // Делаем копию, чтобы не возникало проблем с прямой передачей
    state.currentResale.data = Lodash.cloneDeep(resale);
  },
  SET_CURRENT_RESALE_LOADING: (state, isLoading) => {
    state.currentResale.isLoading = isLoading;
  },

  SET_RESALES_INTEGRATION: (state, resales) => {
    state.resalesIntegration.data = resales;
  },
  SET_RESALES_INTEGRATION_LOADING: (state, isLoading) => {
    state.resalesIntegration.isLoading = isLoading;
  },
  SET_RESALE_INTEGRATION_DETAILS_MODAL_IS_OPENED: (state, isOpen) => (state.modals.RESALE_INTEGRATION_details = isOpen),
  SET_CONFIRM_DELETION_RESALE_INTEGRATION_MODAL_IS_OPENED: (state, isOpen) => (state.modals.confirm_integration_deletion = isOpen),
  SET_CURRENT_RESALE_INTEGRATION: (state, resale) => {
    // Делаем копию, чтобы не возникало проблем с прямой передачей
    state.currentResaleIntegration.data = Lodash.cloneDeep(resale);
  },
  SET_CURRENT_RESALE_INTEGRATION_LOADING: (state, isLoading) => {
    state.currentResaleIntegration.isLoading = isLoading;
  },

  SET_POSTBACKS: (state, postbacks) => {
    state.postbacks.data = postbacks;
  },
};

const actions = {
  GET_RESALES: (context, params) => {
    context.commit("SET_RESALES_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/resale/get", { params })
        .then((data) => {
          context.commit("SET_RESALES", data.data);
          context.commit("SET_RESALES_LOADING_SUCCESS", true);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_RESALES_LOADING", false);
        });
    });
  },
  GET_RESALES_INTEGRATION: (context) => {
    context.commit("SET_RESALES_INTEGRATION_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/resale-integration/get")
        .then((data) => {
          context.commit("SET_RESALES_INTEGRATION", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_RESALES_INTEGRATION_LOADING", false);
        });
    });
  },

  GET_POSTBACKS: (context, params) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/resale-integration/postbacks`, { params })
        .then((data) => {
          context.commit("SET_POSTBACKS", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  RESALE_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_RESALE_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  RESALE_INTEGRATION_DETAILS_MODAL: (context, isOpen) => {
    context.commit("SET_RESALE_INTEGRATION_DETAILS_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_RESALE_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_RESALE_MODAL_IS_OPENED", isOpen);
  },
  CONFIRM_DELETION_RESALE_INTEGRATION_MODAL: (context, isOpen) => {
    context.commit("SET_CONFIRM_DELETION_RESALE_INTEGRATION_MODAL_IS_OPENED", isOpen);
  },
  GET_RESALE_BY_ID: (context, resale_id) => {
    context.commit("SET_CURRENT_RESALE_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/resale/get/${resale_id}`)
        .then((data) => {
          context.commit("SET_CURRENT_RESALE", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_LOADING", false);
        });
    });
  },
  GET_RESALE_INTEGRATION_BY_ID: (context, resale_id) => {
    context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/resale-integration/get/${resale_id}`)
        .then((data) => {
          context.commit("SET_CURRENT_RESALE_INTEGRATION", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", false);
        });
    });
  },
  UPDATE_CURRENT_RESALE: (context, resale) => {
    context.commit("SET_CURRENT_RESALE_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(resale);
      AxiosClient.post(`/resale/update/${resale.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_LOADING", false);
        });
    });
  },

  UPDATE_RESALE_INTEGRATION: (context, resale) => {
    context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(resale);
      AxiosClient.post(`/resale-integration/update/${resale.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", false);
        });
    });
  },

  CREATE_RESALE: (context, resale) => {
    context.commit("SET_CURRENT_RESALE_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(resale);
      AxiosClient.post("/resale/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_LOADING", false);
        });
    });
  },

  CREATE_RESALE_INTEGRATION: (context, resale) => {
    context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", true);
    return new Promise((resolve, reject) => {
      const form = toFormData(resale);
      AxiosClient.post("/resale-integration/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", false);
        });
    });
  },

  CURRENT_RESALE: (context, resale) => {
    context.commit('SET_CURRENT_RESALE', resale);
  },

  CURRENT_RESALE_INTEGRATION: (context, resale) => {
    context.commit('SET_CURRENT_RESALE_INTEGRATION', resale);
  },

  DELETE_RESALE: (context, resaleId) => {
    context.commit("SET_CURRENT_RESALE_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/resale/delete/${resaleId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_LOADING", false);
        });
    });
  },

  DELETE_RESALE_INTEGRATION: (context, resaleId) => {
    context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post(`/resale-integration/delete/${resaleId}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CURRENT_RESALE_INTEGRATION_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
