import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/profiles",
    name: "Список пользователей",
    component: () => import("./views/profileTable"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/profile",
    name: "Профиль",
    component: () => import("./views/profile"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
