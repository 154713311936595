import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/applications",
    name: "Лендинги",
    component: () => import("./views/applicationsList"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/application/update/:id",
    name: "Обновление лендинга",
    component: () => import("./views/updateApplication"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/application/create",
    name: "Создание лендинга",
    component: () => import("./views/updateApplication"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/application/details/:id",
    name: "Информация о лендинге",
    component: () => import("./views/updateApplication"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
