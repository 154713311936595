import collectionsRoutes from './collections/collections-routes';
import jsonRoutes from '@/app/client/collections_settings/json/json-routes';
import dataRoutes from '@/app/client/collections_settings/data/data-routes';

const settingsRoutes = [
  {
    path: "/firebases/:idFirebase",
    name: "Настройки Firebase",
    component: () => import("./panel"),
    children: [
      ...collectionsRoutes,
      ...jsonRoutes,
      ...dataRoutes
    ],
  },
];

export default settingsRoutes;
