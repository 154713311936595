import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  settingsFirebase: {
    data: [],
    isLoading: false,
  },
};

const getters = {
  GET_JSON_SETTINGS: (state) => state.settingsFirebase.data,
  IS_JSON_SETTINGS_LOADING: (state) => state.settingsFirebase.isLoading,
};

const mutations = {
  SET_JSON_SETTINGS: (state, settings) => {
    state.settingsFirebase.data = settings;
  },
  SET_JSON_SETTINGS_LOADING: (state, isLoading) => {
    state.settingsFirebase.isLoading = isLoading;
  },
};

const actions = {
  GET_JSON_SETTINGS: (context, id) => {
    context.commit("SET_JSON_SETTINGS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/firebases/${id}/settings`)
        .then((data) => {
          context.commit("SET_JSON_SETTINGS", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_JSON_SETTINGS_LOADING", false);
        });
    });
  },
  UPDATE_JSON_SETTINGS: (context, { idFirebase, formData }) => {
    return new Promise((resolve, reject) => {
      AxiosClient.put(`/firebases/${idFirebase}/settings`, formData)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          let errorMessage;

          switch (error.response.data.error) {
            case 'File not found in request':
              errorMessage = "Файл не найден в запросе";
              break;
            case 'Failed to save the file':
              errorMessage = "Не удалось сохранить файл";
              break;
            default:
              errorMessage = "Произошла ошибка при обновлении настроек";
          }

          reject(errorMessage);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
