import { AxiosClient } from "@/app/shared/services";

const state = {
  auth: {
    creditionals: {
      phone: "",
      password: "",
    },
    token: localStorage.getItem("token") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    authUser: {},
    loginIsLoading: false,
    status: "",
    isAdmin: sessionStorage.getItem("isAdmin") || 0,
  },
  restorePassword: {
    emailSended: false,
    codeValidated: false,
    newPasswordWasCreated: false,
    isModalOpen: false,
  },
  registration: {
    form: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    credentials: {
      admin_token: localStorage.getItem("admin_token") || "",
      role: localStorage.getItem("role") || "",
    },
    isLoading: false,
    isSuccess: false,
  },
};

const getters = {
  AUTH_CREDITIONALS: (state) => state.auth.creditionals,
  GET_REGISTRATION_CREDITIONALS: (state) => state.registration.form,
  AUTH_TOKEN: (state) => state.auth.token,
  REFRESH_TOKEN: (state) => state.auth.refreshToken,
  AUTH_USER: (state) => state.auth.authUser,
  AUTH_IS_LOADING: (state) => state.auth.loginIsLoading,
  AUTH_STATUS: (state) => state.auth.status,
  IS_LOGGED_IN: (state) => !!state.auth.token,
  REGISTRATION_IS_SUCCESS: (state) => state.registration.isSuccess,
  REGISTRATION_IS_LOADING: (state) => state.registration.isLoading,
  IS_CLIENT: (state) => !state.auth.isAdmin,
  IS_ADMIN: (state) => !!state.auth.isAdmin,
  GET_CREDENTIALS: (state) => state.registration.credentials,
};

const mutations = {
  SET_AUTH_CREDITIONALS: (state, creditionals) => {
    state.auth.creditionals.email = creditionals.email;
    state.auth.creditionals.password = creditionals.password;
  },
  SET_REGISTRATION_CREDITIONALS: (state, creditionals) => {
    state.registration.form.name = creditionals.name;
    state.registration.form.email = creditionals.email;
    state.registration.form.password = creditionals.password;
    state.registration.form.confirmPassword = creditionals.confirmPassword;
  },
  AUTH_SUCCESS: (state, user) => {
    state.auth.token = user.token;
    state.auth.refreshToken = user.refresh_token;
    state.auth.authUser = user;
    state.auth.status = "success";
  },
  SET_REGISTRATION_LOADING: (state, isLoading) => (state.registration.isLoading = isLoading),
  SET_REGISTRATION_IS_SUCCESS: (state, isSuccess) => (state.registration.isSuccess = isSuccess),
  AUTH_FAILED: (state) => {
    state.auth.token = "";
    state.auth.authUser = {};
    state.auth.status = "failed";
  },
  SET_AUTH_IS_LOADING: (state, isLoading) => {
    state.auth.loginIsLoading = isLoading;
  },
  SET_AUTH_USER: (state, user) => {
    state.auth.authUser = user;
  },
  AUTH_LOGOUT: (state) => {
    state.auth.token = "";
    state.auth.status = "";
    state.auth.authUser = {};

    state.auth.isAdmin = false;
  },
  UPDATE_TOKEN: (state, token) => {
    state.auth.token = token;
  },
  SET_CREDENTIALS: (state, credentials) => {
    if (credentials.admin_token) state.registration.credentials.admin_token = credentials.admin_token;
    if (credentials.role) state.registration.credentials.role = credentials.role;
  },
};

const actions = {
  SET_AUTH_USER: (context, user) => {
    context.commit("AUTH_SUCCESS", user);
    localStorage.setItem("token", user.token);
    localStorage.setItem("refreshToken", user.refresh_token);
    AxiosClient.defaults.headers.common.Authorization = `Bearer ${user.token}`;
  },

  SET_AUTH_CREDITIONALS: (context, payload) => {
    context.commit("SET_AUTH_CREDITIONALS", payload);
  },

  SET_REGISTRATION_CREDENTIALS: (context, payload) => {
    context.commit("SET_REGISTRATION_CREDITIONALS", payload);
  },

  AUTH_BY_TOKEN: (context, token) =>
    new Promise((resolve, reject) => {
      context.commit("SET_AUTH_IS_LOADING", true);
      AxiosClient.post("/profile/loginbytoken", { token })
        .then((data) => {
          context.commit(
            "AUTH_SUCCESS",
            data.data
          );
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("refreshToken", data.data.refresh_token);
          AxiosClient.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          if (['admin','teamlead', 'superuser'].includes(data.data.role)){
            state.auth.isAdmin = true;
            sessionStorage.setItem("isAdmin", 1);
          }
          resolve(data);
        })
        .catch((error) => {
          context.commit("AUTH_FAILED");
          reject(error);
        })
        .finally(() => {
          context.commit("SET_AUTH_IS_LOADING", false);
        });
    }),

  AUTH_BY_API_KEY: (context, api_key) =>
    new Promise((resolve, reject) => {
      context.commit("SET_AUTH_IS_LOADING", true);
      AxiosClient.get(`/profile/loginbyapikey?api_key=${api_key}`)
        .then((data) => {
          context.commit(
            "AUTH_SUCCESS",
            data.data
          );
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("refreshToken", data.data.refresh_token);
          AxiosClient.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          if (['admin','teamlead', 'superuser'].includes(data.data.role)){
            state.auth.isAdmin = true;
            sessionStorage.setItem("isAdmin", 1);
          }
        })
        .catch((error) => {
          context.commit("AUTH_FAILED");
          reject(error);
        })
        .finally(() => {
          context.commit("SET_AUTH_IS_LOADING", false);
        });
    }),

  CREDENTIALS_BY_API_KEY: (context, api_key) => {
    return new Promise((resolve, reject) => {
      context.commit("SET_AUTH_IS_LOADING", true);
      AxiosClient.get(`/profile/loginbyapikey?api_key=${api_key}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          context.commit("AUTH_FAILED");
          reject(error);
        })
        .finally(() => {
          context.commit("SET_AUTH_IS_LOADING", false);
        });
    });
  },

  AUTH: (context) =>
    new Promise((resolve, reject) => {
      context.commit("SET_AUTH_IS_LOADING", true);
      AxiosClient.post("/profile/login", state.auth.creditionals)
        .then((data) => {
          context.commit(
            "AUTH_SUCCESS",
            data.data
          );
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("refreshToken", data.data.refresh_token);
          AxiosClient.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
          if (['admin','teamlead', 'superuser'].includes(data.data.role)){
            state.auth.isAdmin = true;
            sessionStorage.setItem("isAdmin", 1);
          }
          resolve(data);
        })
        .catch((error) => {
          context.commit("AUTH_FAILED");
          reject(error);
        })
        .finally(() => {
          context.commit("SET_AUTH_IS_LOADING", false);
        });
    }),

  LOGOUT: (context) =>
    new Promise((resolve, reject) => {
      AxiosClient.post("/profile/logout")
        .then(() => resolve())
        .catch(() => reject())
        .finally(function () {
          context.commit("AUTH_LOGOUT");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          delete AxiosClient.defaults.headers.common.Authorization;
        });
    }),

  GET_AUTH_USER: (context) => {
    context.commit("SET_AUTH_IS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/profile/authuser")
        .then((data) => {
          context.commit("SET_AUTH_USER", data.data);
          if (['admin','teamlead', 'superuser'].includes(data.data.role)){
            state.auth.isAdmin = true;
            sessionStorage.setItem("isAdmin", 1);
          }
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_AUTH_IS_LOADING", false);
        });
    });
  },

  REGISTRATE: (context) => {
    context.commit("SET_REGISTRATION_LOADING", true);
    const params = { ...context.state.registration.form, ...context.state.registration.credentials };
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profile/registration", params)
        .then((data) => {
          const response = data.data;
          if (response && response.uuid) {
            context.commit("SET_REGISTRATION_IS_SUCCESS", true);
            context.commit("SET_USER_CREDITIONALS", response);
            context.commit("SET_USER_UUID", response.uuid);
            context.commit("SET_USER_APIKEY", response.api_key);
            localStorage.setItem("profileUUID", response.uuid);
            localStorage.setItem("profileAPIKey", response.api_key);
          }
          resolve(response);
        })
        .catch((error) => {
          context.commit("SET_REGISTRATION_IS_SUCCESS", false);
          reject(error);
        })
        .finally(() => {
          context.commit("SET_REGISTRATION_LOADING", false);
        });
    });
  },
  STORE_CREDENTIALS: (context, route) => {
    const credentials = {
      admin_token: route.query.admin_token,
      role: route.query.role,
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    };
    if (credentials.admin_token) localStorage.setItem("admin_token", credentials.admin_token);
    if (credentials.role) localStorage.setItem("role", credentials.role);
    context.commit("SET_CREDENTIALS", credentials);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
