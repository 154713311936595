<template>
  <footer class="footer">
    <div class="content has-text-centered" v-if="this.$route.path === '/sms/send' || this.$route.path === '/404'">
      <p class="pa-0 ma-0">
        © <a href="https://mizaem.com.kz/">Mizaem-SMS</a> Отправка умных смс рассылок, 2022
      </p>
    </div>
    <div class="content has-text-centered" v-if="this.$route.path === '/sms/redirect'">
      <p class="pa-0 ma-0">
        © <a href="https://mizaem.com.kz/">Mizaem</a> Умный подбор офферов, 2022
      </p>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
};
</script>

<style>
.footer {
  background-color: #ffffff;
  border-top: solid 1px #e4e4e4;
}
</style>
