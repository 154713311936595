import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import("./views/statistics"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
