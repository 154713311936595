import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/tasks",
    name: "Список задач",
    component: () => import("./views/tasksList"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
