import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "collections",
    name: "Коллекции",
    component: () => import("./views/collectionsTable.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  }
];
