import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/offer/update/:id",
    name: "Обновление оффера",
    component: () => import("./views/updateOffer"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/offers",
    name: "Офферы",
    component: () => import("./views/offersList"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
